<template>
  <div class="companyIntroduction">
    <quill-editor
      ref="myQuillEditor"
      :options="editorOption"
      v-model="content"
      class="myQuillEditor yunmu-ql-editor"
      style="white-space:pre-wrap;"
    />
    <el-upload
      v-show="false"
      :action="$uploadURL"
      :show-file-list="false"
      :on-success="quillSuccess"
    >
      <el-button size="small" type="primary" id="quill-image"
        >点击上传</el-button
      >
    </el-upload>
    <div class="btn">
      <el-button type="primary" @click="handleSave">保存</el-button>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      content: "",
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
              ["blockquote", "code-block"], // 引用  代码块
              [{ header: 1 }, { header: 2 }], // 1、2 级标题
              [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
              [{ script: "sub" }, { script: "super" }], // 上标/下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              [{ direction: "rtl" }], // 文本方向
              [
                {
                  size: [
                    "12",
                    "14",
                    "16",
                    "18",
                    "20",
                    "22",
                    "24",
                    "28",
                    "32",
                    "36"
                  ]
                }
              ], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
              [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
              // [{ font: ['songti'] }], // 字体种类
              [{ align: [] }], // 对齐方式
              ["clean"], // 清除文本格式
              ["image", "video"] // 链接、图片、视频
            ],
            // },
            handlers: {
              image: function(value) {
                if (value) {
                  //调用elementUi上传组件替换富文本原生图片上传
                  document.getElementById("quill-image").click();
                } else {
                  this.quill.format("image", false);
                }
              }
            }
          }
        }
      }
    };
  },
  computed: {},
  created() {
    this.fetchData();
  },
  mounted() {},
  methods: {
    quillSuccess(res, file) {
      let quill = this.$refs.myQuillEditor.quill;
      let length = quill.getSelection().index;
      quill.insertEmbed(length, "image", file.response.data.url);
      quill.setSelection(length + 1);
    },
    handleSave() {
      this.$http
        .post("/systemConfig/update", {
          id: 16,
          propertyValue: this.content
        })
        .then(res => {
          if (res.data.code == 200) {
            this.$notify.success({
              title: "提示",
              message: "保存成功"
            });
          } else {
            this.$notify.success({
              title: "提示",
              message: res.data.message
            });
          }
        });
    },
    fetchData() {
      this.$http
        .post("/systemConfig/queryOne", {
          id: 16
        })
        .then(res => {
          if (res.data.code == 200) {
            this.content = res.data.data.propertyValue;
          } else {
            this.$notify.success({
              title: "提示",
              message: res.data.message
            });
          }
        });
    }
  }
};
</script>

<style lang="less" scoped>
.companyIntroduction {
  padding: 0 15px;
  .btn {
    margin-top: 10px;
    text-align: right;
  }
}
</style>
